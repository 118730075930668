.money-page {
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.money-header {
    height: 82px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.money-left {
    display: flex;
    align-items: center;
}

.money-center {
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #F6F7F9;
    padding-top: 10px;
}

.money-center-top {
    display: flex;
    justify-content: space-between;
}

.money-center-top-item {
    width: 48%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding-left: 16px;
}

.money-center-way {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    border-radius: 12px;
    background-color: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
    height: 138px;
}

.money-way-list {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    // border-width: 1px;
    //     border-color: #F03550;
    //     border-style: solid;
    //     border-radius: 8px;
    // margin-bottom: 10px;
}

.money-way-item {
    width: 100%;
    display: flex;
    align-items: center;
    border-width: 0px;
    border-width: 1px;
    border-color: #eb8322;
    border-style: solid;
    border-radius: 8px;
    // border-color: #F03550;
    border-style: solid;
    border-radius: 8px;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.money-way-item2 {
    width: 100%;
    display: flex;
    align-items: center;
    border-width: 0px;
    border-width: 1px;
    border-color: #cccccc;
    border-style: solid;
    border-radius: 8px;
    // border-color: #F03550;
    border-style: solid;
    border-radius: 8px;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.moeny-center-num {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    border-radius: 12px;
    background-color: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 30px;
}

.money-center-input {
    .ant-input {
        font-size: 20px;

        &::placeholder {
            color: #D3D3D3;
            font-size: 20px;
            font-weight: 600;
        }

        ;
    }
}
@primary-color: #0176C1;
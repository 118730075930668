.mobile-page{
    height: 100vh;
    background-color: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
}
.mobile-tel{
    text-align: left;
    color: #050303;
    font-size: 26px;
    font-weight: 600;
    margin-top: 71px;
}
.code-text{
    color:#979797;
    font-size: 15px;
    margin-top: 2px;
    text-align: left;
    margin-bottom: 16px;
}
.bottom{
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 30px;
    color: #979797;
    font-size: 13px;
}
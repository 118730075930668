.walMask{
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    width: 280px;
    height: 196px;
    margin-top: -98px;
    margin-left: -140px;
    background-color:white;
    border-radius: 8px;
    flex-direction: column;
}
.sign-page {
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    &-title {
        font-size: 16px;
        font-weight: 600;
        margin: 20px;
    }
    &-content {
        &-frame {
            width: 100%;
            height: 60vh;
        }
        &-button {
            margin-top: 20px;
        }
    }
}

@primary-color: #0176C1;
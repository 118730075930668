.recharge{
    min-height: 100vh;
    background-color: #F6F7F9;
}
.header{
    height: 82px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin-bottom: 10px;
    background-color: #FFFFFF;
}
.header-left{
    display: flex;
    align-items: center;
}
.header-avatar{
    width: 50px;
    height: 50px;
    border-radius: 35.83px;
    background-color: #EEEEEE;
    margin-right: 8px;
}
.header-Id{
    font-size: 15px;
    line-height: 17.58px;
    color: #979797;
}
.center{
  padding-left: 16px;
  padding-right: 16px;
}
.center-top{
    background-color: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 12px;
    margin-bottom: 20px;
}
.center-top-header{
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 14px;
}
.center-top-header-left{
    color: #343537;
    font-size: 16px;
    line-height: 18.75px;
    font-weight: 600;
}
.center-top-header-right{
    color: #FF850E;
    font-size: 14px;
    line-height: 16.41px;
    display: flex;
    align-items: center;
    font-weight: 400;
}
.center-top-list{
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .ant-input-affix-wrapper {
        border-color: #d9d9d9;
        border-width: 0.5px;
        .ant-input {
          text-overflow: ellipsis;
          &::placeholder {
            color: #999999;
          }
        }
    }
    .ant-input-affix-wrapper-focused{
        border-color: #d9d9d9;
        box-shadow: none;
        border-width: 0.5px;
    }
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: #FF850E;
        border-width: 0.5px;
    }

}
.center-top-list-item{
    width: 49%;
    height: 44px;
    border-color: #B0B0B0;
    border-width: 0.5px;
    border-style: solid;
    border-radius: 8px;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}
.center-top-item-price{
    display: flex;
    flex: 1;
    justify-content:space-between;
}
.center-top-item-price-left{
    color: #050303;
    font-size: 15px;
    font-weight: 400;
}
.center-top-item-price-center{
    color: #979797;
}
.center-top-item-price-right{
    color: #979797;
    margin-right: 16px;
    font-size: 15px;
}
.Mask{
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    height: 196px;
    margin-top: -98px;
    margin-left: -140px;
    background: white;
    border-radius: 8px;
    flex-direction: column;
}
.pay-way{
    background-color: #FFFFFF;
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
    border-radius: 12px;
    margin-bottom: 20px;
    padding-bottom: 16px;
}
.pay-way-text{
    font-size: 16px;
    color: #343537;
    padding-top: 16px;
    padding-bottom: 14px;
    font-weight: 600;
}
.pay-way-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
//警告弹窗
.ant-modal-content {
    border-radius: 8px;
    // width: 82vw;
    margin: auto;
    margin-top: 100px;
}

.ant-modal-footer {
    border: none;
}

.title_text {
    color: rgb(5, 3, 3);
    font-family: pinfang;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
}

.title_text2 {
    color: rgb(52, 53, 55);
    font-family: pinfang;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ant-modal-body {
    padding-bottom: 0;
}

.ant-modal-footer {
/* LOGO橙色 */
    background: rgb(255, 133, 14);
    border-radius: 0 0 8px 8px;
    padding: 0;
}

.okText {
    text-align: center;
    padding: 10px 0;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
}
@primary-color: #0176C1;
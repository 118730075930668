.header_title{
    font-weight: bold;
    font-size: 16px;
   padding-bottom: 10px;
}
.box_out{
    padding: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        justify-content: space-between;
}
.bottom_title{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #e0626a;
}
.starts{
    width: 100% !important;
    margin: 20px 0;
    background-color: #eb8322;
    color: #fff;
    border-radius: 10px;
}
.bottom_text{
    text-align: left;
    font-size: 14px;
    color: #a1a1a1;
}
@primary-color: #0176C1;
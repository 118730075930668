.date{
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-end;
}
.dateModel{
    display: flex;
    flex-direction: column;
}
.dateBtn{
    display: flex;
    padding-left: 26px;
    padding-right: 26px;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding-top: 20px;
    padding-bottom: 16px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}
.login-page{
    height: 100vh;
    background-color: #FFFFFF;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
}
.login-text{
    text-align: left;
    margin-top: 71px;
    font-size: 26px;
    color: #050303;
    font-weight: 600;
    margin-bottom: 50px;
}
.code{
    display: flex;
    margin-top: 22px;
}
.bottom{
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 30px;
    color: #979797;
    font-size: 13px;
}
@primary-color: #0176C1;
.header_box {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
}
.header_left {
  display: flex;
  align-items: center;
}
.header_text {
  margin-left: 17px;
}
.notice_box {
  background-color: #fff6ef;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.main_text {
  width: 95%;
  padding: 0 10px;
  color: #ff850e;
}
.model_text {
  color: #343537;
  font-size: 14px;
  margin-bottom: 16px;
}
.close_box {
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: center;
}
.closeImg {
  margin-top: 24px;
  margin-bottom: 20px;
}

/* .ant-modal-content {
  border-radius: 8px;
  width: 275px !important;
  margin: auto;
  margin-top: 100px;
} */

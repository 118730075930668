.withdrawallist-page{
    height: 100vh;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
}
.withdrawallist{
    flex: 1;
    margin-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
}
.withdrawallist-item{
    display: flex;
    height: 59px;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-bottom: 10px;
}
.buildMask2 {
  position: absolute;
  /* top: 50%;
        left: 50%;
        width: 280px;
        height: 238px; */
  top: 10px;
  left: 10px;
  width: 95vw;
  /* height: 90vh; */
  /* min-height: 450px; */
  display: flex;
  overflow: auto;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  flex-direction: column;
}
.buildMask3 {
  position: absolute;
  /* top: 50%;
        left: 50%;
        width: 280px;
        height: 238px; */
  top: 20px;
  left: 10px;
  width: 95vw;
  /* height: 400px; */
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  flex-direction: column;
}
.upImg {
  width: 100%;
  height: 100px;
  display: flex;
  gap: 10px;
}
.upData {
  flex: 1;
  box-sizing: border-box;
}
.imgBox {
  width: 100%;
  height: 100%;
  padding: 4px;
}
.imgText {
  margin-top: -9px;
}
/* .avatar-uploader{
    width: 500px;
} */
.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100px;
  background-image: url("./img/img_IDcard02@3x-1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.avatar-uploaderB .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100px;
  background-image: url("./img/img_IDcard02@3x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.ant-modal {
  top: 0;
}
.ant-modal-content {
  margin-top: 40px;
}
.modalClass {
  width: 100%;
  height: 80vh;
  overflow: auto;
}
